<template>
  <div class="robotyy">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="maintitle">
          <img src="../../assets/img/robot2title.png" @click="robot0" />
        </div>
      </el-header>
      <!-- 主体内容 -->
      <el-main>
        <div class="robotyyback"></div>
        <div class="robotyyinfo">
          <div class="tal">预约订购登记</div>
          <div class="tex">
            请留下您的联系方式，工作人员会在1-2个工作日内与您取得联系！
          </div>
          <div class="info">
            <div class="infoval">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="91px"
                :inline="true"
                label-position="left"
                class="demo-ruleForm"
              >
                <el-form-item label="联系人" prop="userName">
                  <el-input
                    v-model="ruleForm.userName"
                    style="width: 280px"
                    placeholder="请输入联系人姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="预约类型" prop="type" class="left">
                  <el-radio-group v-model="ruleForm.type">
                    <el-radio label="1">产品咨询</el-radio>
                    <el-radio label="2">产品订购</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                  <el-input
                    type="text"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    v-model="ruleForm.phone"
                    style="width: 280px"
                    placeholder="请输入联系电话"
                    maxlength="11"
                  ></el-input>
                </el-form-item>
                <el-form-item label="订购台数" prop="num" class="left">
                  <el-input
                    type="text"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    v-model="ruleForm.num"
                    style="width: 280px"
                    placeholder="请输入订购台数"
                    maxlength="5"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input
                    type="textarea"
                    v-model="ruleForm.remark"
                    style="width: 700px"
                    :autosize="{ minRows: 5 }"
                    placeholder="最多输入100字"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
                <el-form-item class="btn">
                  <el-button ref="affirm" @click="submitForm('ruleForm')"
                    >提交预约</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <div class="fotter">
          <div class="fotterdiv">
            <div class="yuedong">
              <img src="../../assets/img/8quanjqr.png" />
              <div class="text1">
                © Copyright 2008 yedone.com all rights reserved.
                渝网文[2013]0887-006号
              </div>
              <div class="text2">
                经营许可证：渝B2-20190234&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="zhizhao">营业执照</span><br />渝ICP备09014244号-16
              </div>
              <div class="text3">
                <img src="../../assets/img/guohui.png" />
                <div class="text3tel" @click="beian">
                  渝公网安备 50010802002169号
                </div>
              </div>
            </div>
            <div class="bor">
              <div class="bor1"></div>
              <div class="bor2"></div>
            </div>
            <div class="contactUs">
              <div class="women">联系我们</div>
              <div class="phone">
                <div class="dianhua1">
                  <div>客服电话（24h）</div>
                  <div>400-680-8811</div>
                </div>
                <div class="dianhua2">
                  <div>商务电话（工作日09:00-18:00)</div>
                  <div>023-68636622</div>
                </div>
              </div>
              <div class="phone2">
                <div class="dianhua1">
                  <div>客服QQ（24h）</div>
                  <div>4006808811</div>
                </div>
                <div class="dianhua2">
                  <div>公司地址</div>
                  <div>重庆市南岸区南城大道199号正联大厦3楼</div>
                </div>
              </div>
            </div>
            <div class="attention">
              <div class="women">关注我们</div>
              <div class="erweima">
                <div class="fuwu">
                  <img src="../../assets/img/qushangwang.png" />
                  <div>去上网APP微信服务号</div>
                </div>
                <div class="gongzong">
                  <img src="../../assets/img/baquan.jpg" />
                  <div>8圈计费微信公众号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
    <!-- 蒙层 -->
    <div class="popup" v-show="show">
      <div class="popup-info">
        <div class="mar">
          <img src="../../assets/img/success.png" v-if="code == 1" />
          <img src="../../assets/img/err.png" v-else />
          <div>{{ Message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "robotyy",
  data() {
    return {
      show: false, //是否显示蒙层
      code: null, //蒙层显示图标
      Message: "", //蒙层显示文字
      ruleForm: {
        userName: "", //姓名
        phone: "", //电话
        num: "", //订购台数
        type: "1", //预约类型
        remark: "", //备注
      },
      rules: {
        userName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择预约类型", trigger: "change" },
        ],
        num: [
          { required: true, message: "请输入意向订购台数", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
  methods: {
    //跳转首页
    robot0() {
      this.$router.push("/robot0");
    },
    //跳转产品介绍
    robot1() {
      this.$router.push("/robot1");
    },
    //跳转功能介绍
    robot2() {
      this.$router.push("/robot2");
    },
    //跳转更新说明
    robot3() {
      this.$router.push("/robot3");
    },
    //跳转预约订购
    robotyy() {
      // this.$router.push("/robotyy");
      //在新页面打开
      let routeUrl = this.$router.resolve("/robotyy");
      window.open(routeUrl.href, "_blank");
    },
    //跳转备案
    beian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802002169"
      );
    },
    //跳转营业执照
    zhizhao(){
      let routeData = this.$router.resolve('/yinye');
      window.open(routeData.href, '_blank');
    },
    //点击提交预约
    submitForm(formName) {
      // 验证是否通过
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //发送请求后，返回响应结果之前，禁止鼠标点击该元素
          this.$refs.affirm.$el.style.pointerEvents = "none";
          this.$http.post("/api/robot", this.ruleForm).then((res) => {
            let a = JSON.parse(res.data);
            if (a.Code == 1) {
              this.code = 1;
              this.Message = a.Message;
              this.show = true;
              setTimeout(() => {
                this.show = false;
                //提交成功清空数据
                this.ruleForm.userName = ""; //姓名
                this.ruleForm.phone = ""; //电话
                this.ruleForm.num = ""; //订购台数
                this.ruleForm.type = "1"; //预约类型
                this.ruleForm.remark = ""; //备注
              }, 2000);
            } else {
              this.code = 2;
              this.Message = a.Message;
              this.show = true;
              setTimeout(() => {
                this.show = false;
              }, 2000);
            }
            //响应结果后，无论成功失败，都允许点击该元素
            this.$refs.affirm.$el.style.pointerEvents = "";
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1200px;
  height: 80px !important;
  box-sizing: border-box;
  padding: 21px 0 0;
  margin: 0 auto;
  .maintitle {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    > img {
      width: 182px;
      height: 40px;
      margin-right: 50px;
      &:hover {
        cursor: pointer; //鼠标变小手
      }
    }
    > div {
      width: 72px;
      height: 27px;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 27px;
      color: #626778;
      margin-left: 80px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      opacity: 1;
      position: relative;
      color: #0e6eff;
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        background: #0e6eff;
        opacity: 1;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}
//主体内容样式
.el-main {
  width: 100%;
  min-width: 1200px;
  padding: 0;
  margin: 0 auto;
  .robotyyback {
    margin: 0 auto;
    height: 520px;
    background-image: url(../../assets/img/robotyyback.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position:center;
  }
  .robotyyinfo {
    box-sizing: border-box;
    padding-top: 40px;
    .tal {
      width: 1200px;
      height: 47px;
      font-size: 32px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 47px;
      color: #2d394b;
      margin: 0 auto;
    }
    .tex {
      width: 1200px;
      height: 29px;
      font-size: 20px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      line-height: 29px;
      color: #2d394b;
      margin: 20px auto 0;
    }
    .info {
      width: 1200px;
      margin: 40px auto 0;
      .infoval {
        width: 810px;
        .left {
          margin-left: 40px;
        }
        .el-button {
          width: 160px;
          height: 58px;
          background: #0e6eff;
          border-radius: 8px;
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #ffffff;
          margin-left: 91px;
        }
        // 输入文字样式
        /deep/ input {
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #2d394b;
        }
        .btn {
          margin-bottom: 70px;
        }
      }
    }
  }
}

//底部
.el-footer {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0;
  .fotter {
    height: 338px;
    background: #0b192b;
    padding-top: 51px;
    box-sizing: border-box;
    .fotterdiv {
      width: 1200px;
      height: 225px;
      margin: 0 auto;
      display: flex;
      .yuedong {
        width: 273px;
        height: 225px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 21px;
        color: #626778;
        > img {
          width: 182px;
          height: 40px;
        }
        .text1 {
          margin-top: 47px;
        }
        .text2 {
          margin-top: 14px;
          span:hover{cursor: pointer;color: #ffffff;}
        }
        .text3 {
          margin-top: 14px;
          display: flex;
          justify-items: center;
          img {
            margin-right: 10px;
          }
          .text3tel {
            &:hover {
              cursor: pointer;
              color: #ffffff;
            }
          }
        }
      }
      .bor {
        width: 1px;
        height: 225px;
        margin-left: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .bor1 {
          flex: 1;
        }
        .bor2 {
          height: 120px;
          border: 1px solid #1b2c43;
        }
      }
      .contactUs {
        width: 475px;
        height: 218px;
        margin-left: 50px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
        }
        .phone,
        .phone2 {
          margin-top: 57px;
          display: flex;
          .dianhua1,
          .dianhua2 {
            div:nth-child(1) {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #626778;
            }
            div:nth-child(2) {
              height: 22px;
              font-size: 18px;
              font-family: DIN;
              color: #ffffff;
              margin-top: 8px;
            }
          }
          .dianhua1 {
            width: 124px;
          }
          .dianhua2 {
            margin-left: 52px;
          }
        }
        .phone2 {
          margin-top: 30px;
          .dianhua2 div:nth-child(2) {
            font-family: Source Han Sans SC;
            font-size: 16px;
          }
        }
      }
      .attention {
        width: 298px;
        height: 218px;
        margin-left: 67px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
          margin-left: 10px;
        }
        .erweima {
          display: flex;
          margin-top: 51px;
          .fuwu,
          .gongzong {
            font-size: 14px;
            font-weight: 400;
            color: #626778;
            text-align: center;
            > img {
              width: 110px;
              height: 110px;
            }
            > div {
              margin-top: 8px;
            }
          }
          .gongzong {
            margin-left: 38px;
          }
        }
      }
    }
  }
}

// 蒙层样式
.popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998;

  .popup-info {
    position: fixed;
    width: 590rpx;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30rpx;
    border-radius: 20rpx;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9999;
    .mar {
      height: 68px;
      background: #0b192b;
      opacity: 0.8;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 0 30px;
      > img {
        width: 28px;
        height: 28px;
      }
      > div {
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 18px;
        color: #ffffff;
        margin-left: 10px;
      }
    }
  }
}
</style>
